import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchTermSubject = new BehaviorSubject<string>('');
  public searchTerm$ = this.searchTermSubject.asObservable();

  public setSearchTerm(term: string) {
    this.searchTermSubject.next(term);
  }

  public clearSearch() {
    this.searchTermSubject.next('');
  }
}
