import { Injectable } from '@angular/core';
import { PermissionAccessService } from './permission-access.service';
import { PermissionConstants } from '../utils/constants/permission-constants';

@Injectable({ providedIn: 'root' })
export class PilotCrewAccessService {
  public permissionConstants = PermissionConstants;

  constructor(private permissionAccess: PermissionAccessService) {}

  public canViewCertifications(): boolean {
    return this.permissionAccess.hasPermission(
      this.permissionConstants.AppViewCertificationsModulePermissions,
    );
  }

  public canViewAllCertifications(): boolean {
    return this.permissionAccess.hasPermission(
      this.permissionConstants.AppViewAllCertificationsPermissions,
    );
  }

  public canViewPersonalCertifications(): boolean {
    return this.permissionAccess.hasPermission(
      this.permissionConstants.AppViewPersonalCertificationsPermissions,
    );
  }

  public canToggleShowAllCertifications(): boolean {
    return this.permissionAccess.hasPermission(
      this.permissionConstants.AppCanToggleShowAllCertificationPermissions,
    );
  }

  public canEditCertification(): boolean {
    return this.permissionAccess.hasPermission(
      this.permissionConstants.AppEditCertificationPermissions,
    );
  }

  public canRenewCertification(): boolean {
    return this.permissionAccess.hasPermission(
      this.permissionConstants.AppRenewalCertificationPermissions,
    );
  }
}
