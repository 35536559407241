import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { User } from '../models/user.model';
import { environment } from '../../../../fms-web/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends DataService {
  constructor(public override http: HttpClient) {
    super(http, `${environment.authApiUrlPart}/Account`);
  }

  public getCurrentUser(): Observable<User> {
    const path = 'me';

    return super.getOneByPath<User>(path);
  }
}
