import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

// TODO: To be replaced with the permission service
@Directive({
  selector: '[permission]',
  standalone: true,
})
export class PermissionDirective implements OnInit {
  @Input('permission') requiredPermissions!: string[];
  private helper = new JwtHelperService();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.checkPermissions();
  }

  private checkPermissions() {
    const token = localStorage.getItem('auth_token');
    if (token) {
      try {
        const decodedToken = this.helper.decodeToken(token);
        const userPermissions: string[] = decodedToken.Policy || [];

        // Check if the user has at least one of the required permissions
        const hasPermission = this.requiredPermissions.some(permission =>
          userPermissions.includes(permission)
        );

        if (!hasPermission) {
          this.hideElement();
        }
      } catch {
        this.hideElement();
      }
    } else {
      this.hideElement();
    }
  }

  private hideElement() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
  }
}
